<template>
  <div>
    <p>Not Yet Migrated to New Site.</p>
  </div>
</template>

<script>
export default {
  data(){
    return {
      
    }
  }
}
</script>